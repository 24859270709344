import { PHONE, EMAIL } from './pattern';
import { message } from 'antd';
/**
 * 存放全局公用方法
 */
const fileTypeList = [ 'doc','docx','pdf' , 'xls' , 'xlsx']

class utils {
    // 获取url参数
    querySearch(param) {
        var pattern = /([^?&=]+)=([^&#]*)/g;
        var dict = {};
        var search = null;
        if (typeof param === 'object' && param instanceof Location) {
            search = param.search;
        } else if (typeof param === 'string') {
            search = param;
        } else {
            throw new Error('参数类型非法！请传入window.loaction对象或者url字符串。');
        }
        search.replace(pattern, function (rs, $1, $2) {
            var key = decodeURIComponent($1);
            var value = decodeURIComponent($2);
            dict[key] = value;
            return rs;
        });
        return dict;
    }

    // 接口频繁调用
    debounce(func, wait = 500) {
        let timeout; // 定时器变量
        return function (event) {
            clearTimeout(timeout); // 每次触发时先清除上一次的定时器,然后重新计时
            event.persist && event.persist(); //保留对事件的引用
            //const event = e && {...e}   //深拷贝事件对象
            timeout = setTimeout(() => {
                func(event);
            }, wait); // 指定 xx ms 后触发真正想进行的操作 handler
        };
    }
    // 手机号、身份证、邮箱加密显示
    encryptionShow(value) {
        if (PHONE['zh-CN'].test(value)) {
            let end = value.length;
            return `${value.slice(0, 3)}****${value.slice(end - 4, end)}`
        }
        if (EMAIL.test(value)) {
            return `${value.slice(0, 3)}****@${value.split('@')[1]}`
        }
        return value;
    }
    // 下载图片
    downImg(img) {
        console.log('执行下载图片', img);
    }
    // 将base64转换为文件
    dataBasetoFile(imgData, filename) {
        var arr = imgData.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = window.atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }
    // 图片上传校验
    beforeUpload(file) {
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' ||
            file.type === 'image/svg+xml';
        if (!isJPG) {
            message.error('只能上传jpg、png、svg图片！');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('上传图片不得大于2M!');
        }
        return isJPG && isLt2M;
    }
    //文件上传校验
    beforeFileUpload(file) {
        const filename = file.name;
        let isDoc = false
        // 将文件名反转
        let filenameReverse = filename.split('').reverse().join('');
        // 获取第一个匹配.XX的字符串
        let type = filenameReverse.substring(0, filenameReverse.search(/\./)).split('').reverse().join('');

        if (fileTypeList.includes(type) ) {
            isDoc = true
        }
        if (!isDoc) {
            message.error('只能上传.doc、.docx、.pdf、.xls、.xlsx文件！');
        }
        const isLt2M = file.size / 1024 / 1024 < 20;
        if (!isLt2M) {
            message.error('上传文件不得大于20M!');
        }
        return isDoc && isLt2M;
    }
    // 将图片旋转90度
    imgTranslate90(srcBase64, callback) {
        let srcOrientation = 8;
        var img = new Image();
        img.onload = function() {
            var width = img.width,
                height = img.height,
                canvas = document.createElement('canvas'),
                ctx = canvas.getContext("2d");

            // set proper canvas dimensions before transform & export
            if ([5,6,7,8].indexOf(srcOrientation) > -1) {
                canvas.width = height;
                canvas.height = width;
            } else {
                canvas.width = width;
                canvas.height = height;
            }
            // transform context before drawing image
            switch (srcOrientation) {
                case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
                case 3: ctx.transform(-1, 0, 0, -1, width, height ); break;
                case 4: ctx.transform(1, 0, 0, -1, 0, height ); break;
                case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
                case 6: ctx.transform(0, 1, -1, 0, height , 0); break;
                case 7: ctx.transform(0, -1, -1, 0, height , width); break;
                case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
                default: ctx.transform(1, 0, 0, 1, 0, 0);
            }
            ctx.fillStyle = 'transparent';
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            // draw image
            ctx.drawImage(img, 0, 0);
            // export base64
            callback(canvas.toDataURL());
        };
        img.src = srcBase64;
    }
    getElementToPageTop(element) {

        var actualTop = element.offsetTop;
        var current = element.offsetParent;

        while (current !== null) {
            actualTop += current.offsetTop;
            current = current.offsetParent;
        }
        return actualTop;
    }
    getElementToPageLeft(element) {
        var actualLeft = element.offsetLeft;
        var current = element.offsetParent;

        while (current !== null) {
            actualLeft += current.offsetLeft;
            current = current.offsetParent;
        }
        return actualLeft;
    }
    // 计算传给接口的x，y
    returnApiData(img, post, divId) {
        let dom = document.getElementById(divId) || {};
        //pc图片固定宽度为780
        let divWidth = 780
        let scale = dom.offsetWidth / 780
        let divHeight = Math.floor(dom.offsetHeight) / scale || 1;
        

        let imgWidth = img.width || divWidth;
        let imgHeight = img.height || divHeight;

        divHeight = (divWidth * imgHeight / imgWidth).toFixed(2);

        let X = post.x;
        let Y = post.y;

        let newX = (imgWidth * X / divWidth).toFixed(2);
        let newY = (imgHeight * Y / divHeight).toFixed(2);

        return {
            x: +newX,
            y: +newY
        }
    }
    // 计算传递给接口的签章、日期的宽度
    returnImgWidth(attachmentWidth, divId, imgWidth) {
        let newImgWidth = imgWidth;
        let dom = document.getElementById(divId) || {};
        let divWidth = 800 || 1;

        attachmentWidth = attachmentWidth ? attachmentWidth : divWidth;

        newImgWidth = attachmentWidth * imgWidth / divWidth;
        return newImgWidth
    }
    // 计算展示的x，y
    returnShowData(img, post, divId) {

        let dom = document.getElementById(divId) || {};
        let divWidth = 800 || 1;
        let divHeight = dom.offsetHeight || 1;

        let imgWidth = img.width || divWidth;
        let imgHeight = img.height || divHeight;
        divHeight = (divWidth * imgHeight / imgWidth).toFixed(2);

        let X = post.x;
        let Y = post.y;

        let newX = (divWidth * X / imgWidth).toFixed(2);;
        let newY = (divHeight * Y / imgHeight).toFixed(2);
        // console.log('returnShowData=====')
        // console.log('x', post.x, 'divWidth', divWidth, 'imgWidth', imgWidth, 'newX', newX)
        // console.log('y', post.y, 'divHeight', divHeight, 'imgHeight', imgHeight, 'newY', newY)

        return {
            x: +newX,
            y: +newY
        }
    }
    // 计算接口返回的签章的宽度，计算实际的宽度
    returnshowImg(attachmentWidth, divId, imgWidth) {
        let newImgWidth = imgWidth;
        let dom = document.getElementById(divId) || {};
        let divWidth = 800 || 1;

        attachmentWidth = attachmentWidth ? attachmentWidth : divWidth;

        newImgWidth = divWidth * imgWidth / attachmentWidth;

        return newImgWidth
    }
}

export default new utils();
