/**
 * Created by PC on 2020/7/18.
 */
import React, { Component } from 'react';
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        console.error('err',error)
        window.location.href= '/'
    }
    static getDerivedStateFromError(error) {
        console.error('err',error)
        window.location.href= '/'
    }

    render() {
        return this.props.children;
    }
}
export default ErrorBoundary