import axios from '../common/http';

// 通过token获取用户信息
export const getUser = (query) => {
    return axios.get('/api/openapi/getuser', query);
};

// 更新user
export const updateUser = (query) => {
    return axios.post('/api/openapi/updateuser', query);
};

export const getToken = (query) => {
    return axios.post('/api/openapi/gettoken', query);
};

//模板详情
export const templateView = (query) => {
    return axios.post('/api/thirdParty/templateView', query);
};

//添加签章
export const addSignature = (query) => {
    return axios.post('/api/thirdParty/addSignature', query);
};

//创建合同
export const createSign = (query) => {
    return axios.post('/api/thirdParty/createSign', query);
};

//签署合同
export const signIn = (query) => {
    return axios.post('/api/thirdParty/signIn', query);
};

//校验后台登录状态
export const getAccountDetail = (query) => {
    return axios.get(`/api/openapi/getAccountDetail`, query);
};

//模板id解码
export const decodeId = (query) => {
    return axios.get(`/api/thirdParty/decodeTemplateId`, query);
};

// 验证码预校验
export const preCheck = (query) => {
    return axios.get('/api/thirdParty/preCheck', query)
};

// 合同编号和signID转换
export const getSignIdByNo = (query) => {
    return axios.get('/api/thirdParty/getSignIdByNo', query)
};

// 获取templateID或signID
export const getTemplateIdOrSignId = (query) => {
    return axios.get('/api/thirdParty/getTemplateIdOrSignId', query)
};

// 获取个人身份证和姓名
export const getRealNameBySessionId = (query) => {
    return axios.get('/api/thirdParty/getRealNameBySessionId', query)
};