import React, { Component, Fragment } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import URoute from '@components/URoute';
import IRoute from '@components/IRoute';
import { observer } from 'mobx-react';
import userInfo from '@store/userInfo.store';
import cookie from 'js-cookie';
import { toJS } from 'mobx';
import './App.scss'
import './common.scss'

const CustomFilecenter = React.lazy(() => import(/* webpackChunkName: "filecenter" */ './custom/pages/filecenter'));
const CustomSignDetail = React.lazy(() => import(/* webpackChunkName: "filecenter" */ './custom/pages/filecenter/signDetail'));
const CustomRealname = React.lazy(() => import(/* webpackChunkName: "filecenter" */ './custom/pages/realName'));
const Filecenter = React.lazy(() => import(/* webpackChunkName: "filecenter" */ './pages/pages/filecenter'));
const IndexPage = React.lazy(() => import(/* webpackChunkName: "userindex" */ './pages/pages/indexPage'));
const Usercenter = React.lazy(() => import(/* webpackChunkName: "usercenter" */ './pages/pages/usercenter'));

@observer
class App extends Component {
    componentDidMount() {
        //获取用户信息，判断登录状态
        userInfo.getUserInfo({}, () => { })
        if (cookie.get('thirdtype')) {
            let thirdtype = Number.parseInt(cookie.get('thirdtype'))
            userInfo.setThirdType(thirdtype)
        } else {
            cookie.set('thirdtype', 0)
        }
    }
    render() {
        //是否为爱豆用户
        const fromThird = toJS(userInfo.thirdType) === 2 || toJS(userInfo.thirdType) === 3
        return <Fragment>
            {fromThird ?
                <Fragment>
                    {toJS(userInfo.user.isReal) === 1 &&
                        <Fragment>
                            {/*三方的用户签署*/}
                            {toJS(userInfo.thirdType) === 2 && <CustomFilecenter />}
                            {/*三方用户的签署详情*/}
                            {toJS(userInfo.thirdType) === 3 && <Switch>
                                <IRoute path="/custom/signDetail" title="签署详情" render={(props) => { return <CustomSignDetail {...props} /> }} />
                                <Redirect to="/custom/signDetail" />
                            </Switch>}
                        </Fragment>}
                    {toJS(userInfo.user.isReal) === 0 && <Switch>
                        <IRoute path="/custom/realname" title="实名认证" render={(props) => { return <CustomRealname {...props} /> }} />
                        <Redirect to="/custom/realname" />
                    </Switch>}
                </Fragment> :
                <Fragment>
                    {!toJS(userInfo.thirdType) && <Switch>
                        <URoute path="/file" title="文件中心" render={(props) => { return <Filecenter {...props} /> }} />
                        <URoute path="/user" title="用户中心" render={(props) => { return <Usercenter {...props} /> }} />
                        <URoute path="/" title="首页" render={(props) => { return <IndexPage {...props} /> }} />
                    </Switch>}
                </Fragment>}
        </Fragment>
    }
}


export default App;
