import axios from '../common/http';
import {ToastMsg} from '../common/tool'

// 个人注册（手机）
export const registerPeoplePhone = (query) => {
    return axios.post('/web/register/addPersonalWithPhone', query);
};

// 个人注册（邮箱）
export const registerPeopleEmail = (query) => {
    return axios.post('/web/register/addPersonalWithEmail', query);
};
// 企业注册
export const registerEnterprise = (query) => {
    return axios.post('/web/register/enterpriseRegistration', query);
};
// 个人登陆
export const personalLogin = (query) => {
    return axios.post('/web/login/personalLogin', query);
};
// 企业登陆
export const enterpriseLogin = (query) => {
    return axios.post('/web/login/enterpriseLogin', query);
};
// 退出登录
export const logout = (query) => {
    return axios.post('/web/login/logout', query);
}
// 忘记密码_提交重设密码
export const resetPassword = (query) => {
    return axios.post('/web/reset/resetPassword', query);
};
// 发送验证码(手机)
export const sendSmsCode = (query) => {
    return axios.post('/web/msg/sendSmsCode', query);
};
// 检查验证码是否正确(手机)
export const checkSmsCode = (query) => {
    return axios.post('/web/msg/checkSmsCode', query);
};
// 发送验证码(邮箱)
export const sendEmailCode = (query) => {
    return axios.post('/web/msg/sendEmailCode', query);
};
// 检查验证码是否正确(邮箱)
export const checkMailCode = (query) => {
    return axios.post('/web/msg/checkMailCode', query);
};
//使用场景； 100 ： 修改密码 , 101 : 修改提醒手机号 , 102 : 修改提醒邮箱
export const sendCurCode = (query) => {
    return axios.post('/web/msg/send', query);
};
//使用场景； 100 ： 修改密码 , 101 : 修改提醒手机号 , 102 : 修改提醒邮箱
export const checkCurCode = (query) => {
    return axios.post('/web/msg/checkSendCode', query);
};
// 图片上传
export const fileUpload = (query, options) => {
    return axios.post('/web/certification/fileUpload', query, options).then(res=>interceptRes(res));
};
// 企业认证
export const certification = (query) => {
    return axios.post('/web/certification/enterpriseCertification', query);
};
// 企业输入验证码进行认证
export const commitCode = (query) => {
    return axios.post('/web/certification/commitCode', query);
};
// 账户设置-获取当前用户基本信息
export const getAccountDetail = (query,options) => {
    return axios.post('/web/accountManagement/settings/getAccountDetail', query,options);
};
// 账户设置-获取当前用户基本信息
export const modifyPassword = (query) => {
    return axios.post('/web/accountManagement/settings/modifyPassword', query);
};
// 修改手机号 
export const modifyPhone = (query) => {
    return axios.post('/web/accountManagement/settings/modifyPhone', query);
};
// 账户设置-修改电子邮箱
export const modifyEmail = (query) => {
    return axios.post('/web/accountManagement/settings/modifyEmail', query);
};


// 账户设置-修改提醒&通知
export const modifyNotice = (query) => {
    return axios.post('/web/accountManagement/settings/modifyNotice', query);
};
// 账户设置-修改账户头像
export const modifyHeadImg = (query) => {
    return axios.post('/web/accountManagement/settings/modifyHeadImg', query);
};
// 个人中心-获取汇总信息
export const getCount = (query) => {
    return axios.post('/web/center/getCount', query);
};


function interceptRes(res) {
    let msg = res.message ? res.message : '未知异常，请联系管理员！'
    if(res.status===false){ToastMsg(msg)}
    return res
}
