     // 文件状态 btn
     import React from "react";
     import { Toast,Icon} from 'antd-mobile';
     export   let btnStatus = {
        1: 'btn-wait-me' ,
        2: 'btn-wait-other' ,
        3: 'btn-wait-draft' ,
        4: 'btn-complete' ,
        5: 'btn-invalid' ,
        6: 'btn-revoke' ,
        7: 'btn-refuse' ,
        8: 'btn-will-close' ,
    }
       // 文件状态 title
   export   let fileStatus={
        1: '待我签' ,
        2: '待他人签' ,
        3: '草稿' ,
        4: '已完成' ,
        5: '已失效' ,
        6: '已撤销' ,
        7: '已拒签' ,
        8: '即将截止签署' ,
    }
     // 文件状态 title
     export   let validStatus={
        0:'未认证',
         1:'认证成功',
         2:'待审核',
         3:'待确认',
         4:'认证失败'
     }
      // 格式化时间
   export   let getDateByStr= (value)=>{
      return value ? value.substr(0,10) : ''
    }
   export let ToastMsg= (msg)=>{
       Toast.info(<p className='info-tip'><span className='info-text'>{msg}</span></p>, 3, null, false)
   }
   export let getCurDate= ()=>{
       let date = new Date()
       return date.getFullYear() + '-' + formatDouble(date.getMonth()+1) + '-' + formatDouble(date.getDate()) + ' ' +formatDouble(date.getHours()) + ':' + formatDouble(date.getMinutes()) + ':'+formatDouble(date.getSeconds())
   }
     export let getCurDateByStr= ()=>{
         let date = new Date()
         return date.getFullYear() + '年' + formatDouble(date.getMonth()+1) + '月' + formatDouble(date.getDate()) + '日'
     }
     function formatDouble(val) {
         return ('0' + val).substr(-2)
     }