import axios from 'axios';
import cookies from 'js-cookie';
import config from './config';
import {ToastMsg} from '../common/tool'

class HttpRequest {
  constructor () {
    this.baseUrl = config.baseUrl;
  }

  getInsideConfig () { // 配置公共headers
    const config = {
      baseURL: this.baseUrl,
      headers: {
        // 设置头部信息
      }
    };
    return config;
  }
  interceptors (instance, url) {
    // 请求拦截
    instance.interceptors.request.use(
      config => {
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );

    // 响应拦截
    instance.interceptors.response.use(
      res => {
        const { data } = res;
        return data;
      },
      error => {
        let errorInfo = error.response;
        if (!errorInfo) {
          const {
            request: { statusText, status },
            config
          } = JSON.parse(JSON.stringify(error));
          errorInfo = {
            statusText,
            status,
            request: { responseURL: config.url }
          };
        }
        return Promise.reject(error);
      }
    );
  }
  request (options = {}) {
    const instance = axios.create();
    options = Object.assign(this.getInsideConfig(), options);
    console.log('request====',options);
    this.interceptors(instance, options.url);
    return instance(options).then(res => {
      return new Promise((resolve, reject) => {
        if(res.statusCode ==='401'){
            cookies.remove('loginstatus');
            cookies.remove('thirdtype');
            window.location.href = '/login';
        }
        if (res.statusCode !== '200') {
          let err = new Error(res.message);
          err.res = res;
          // 若返回的状态 err_no ！== 0 ，提示错误信息
            //ToastMsg(res.message)
          reject(res);
        };
        resolve(res);
      });
    }).catch(error => {
      throw error;
    });
  }
  get (url, params = {}, options = {}) {
    return this.request({
      method: 'get',
      url,
      params,
      ...options
    });
  }
  post (url, data, options = {}) {
    // data = Object.assign(
    //     {},
    //     data,
    //     { session: Cookies.get(config.tokenName) }
    //   );
    return this.request({
      method: 'post',
      url,
      data,
      ...options
    });
  }
}

export default new HttpRequest();
