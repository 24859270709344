import { observable, action, flow } from 'mobx';
import { Toast } from 'antd-mobile';
import cookie from 'js-cookie';
import {
    getAccountDetail,
    personalLogin, enterpriseLogin,
    registerPeopleEmail, registerPeoplePhone, registerEnterprise, resetPassword,logout
} from '@api/login';
import { toJS } from 'mobx';
class Store {
    // 登陆状态
    @observable loginStatus = false;
    // 设置登陆状态
    @action setLoginStatus(status) {
        this.loginStatus = status;
    }
    // 三方登录状态
    @observable thirdType = 0;
    // 设置三方登陆状态
    @action setThirdType(type) {
        this.thirdType = type;
    }
    // 三方模板id
    @observable templateId = '';
    // 设置三方模板id
    @action setTemplateId(id) {
        this.templateId = id;
    }
    // 三方模板id（加密）
    @observable encodedTemplateId = '';
    // 设置三方模板id
    @action setEncodedTemplateId(id) {
        this.encodedTemplateId = id;
    }
    // 三方签署详情id
    @observable signId = '';
    // 设置三方签署详情id
    @action setSignId(id) {
        this.signId = id;
    }
    // 三方登录token
    @observable token = '';
    // 设置三方登陆token
    @action setToken(token) {
        this.token = token;
    }
    
    // 企业注册、邮箱注册
    @observable registerType = 1

    // 设置 企业注册、邮箱注册
    @action setRegisterType(type) {
        this.registerType = type;
    }

    // 企业注册==注册数据
    @observable registerData = {
        // 手机号、邮箱
        value: '',
        // 密码
        password: '',
        // 验证码
        code: '',
        // 企业名称
        enterpriseName: "",
        // 行业(分类见详细说明)
        trade: {},
        //注册模式  1 手机，2 邮箱
        type:1
    };

    // 企业注册===注册数据
    @action setRegisterData(obj) {
        this.registerData = obj;
    }

    // 忘记密码==接口调用
    @observable password = {
        phoneOrEmail: '',
        newPassword: '',
        verifyCode: ''
    }
    // 设置 忘记密码==接口调用
    @action setPassWord(data) {
        this.password = data
    }
    //用户信息
    @observable user = {};

    // 设置用户信息
    @action setUser(obj) {
        this.user = obj;
        if (obj.type) {
            cookie.set('user_type', obj.type);
        } else {
            cookie.remove('user_type');
        }
    }

    // 获取用户信息
    @action getUserInfo = flow(function* (query, callback,options) {
        try {
            const res = yield getAccountDetail(query,options);
            this.setUser(res.data);
            // console.log(res.data)
            return typeof callback === 'function' && callback(res.data);
        } catch (error) {
            this.setUser({});
            return typeof callback === 'function' && callback(error);
        }
    })

    // 登录、注册成功回调
    successBack = function (mes) {
        Toast.success(mes, 2);
        setTimeout(() => {
            cookie.set('loginstatus', true);
            window.location.reload();
        }, 1000)
    }

    // 退出功回调
    logoutBack = function (mes) {
        cookie.remove('loginstatus');
        this.setUser({})
        window.location.href = '/'
    }

    // 个人用户登录
    @action loginPerson = flow(function* (query, callback) {
        try {
            const res = yield personalLogin(query);
            this.successBack('登录成功！')

            return typeof callback === 'function' && callback(res.data);
        } catch (error) {
            Toast.info(error.message || '登录失败', 2, null, false);
            return typeof callback === 'function' && callback(error);
        }
    })

    // 企业用户登录
    @action loginEnterprise = flow(function* (query, callback) {
        try {
            const res = yield enterpriseLogin(query);
            this.successBack('登录成功！')

            return typeof callback === 'function' && callback(res.data);
        } catch (error) {
            Toast.info(error.message || '登录失败', 2, null, false);
            return typeof callback === 'function' && callback(error);
        }
    })

    // 个人用户注册-邮箱
    @action registerEmail = flow(function* (query, callback) {
        try {
            const res = yield registerPeopleEmail(query);
            this.successBack('注册成功！')

            return typeof callback === 'function' && callback(res.data);
        } catch (error) {
            Toast.info(error.message || '注册失败', 2, null, false);
            return typeof callback === 'function' && callback(error);
        }
    })

    // 个人用户注册-手机号
    @action registerPhone = flow(function* (query, callback) {
        try {
            const res = yield registerPeoplePhone(query);
            this.successBack('注册成功！')

            return typeof callback === 'function' && callback(res.data);
        } catch (error) {
            Toast.info(error.message || '注册失败', 2, null, false);
            return typeof callback === 'function' && callback(error);
        }
    })

    // 企业用户注册
    @action registerEnterPrise = flow(function* (query, callback) {
        try {
            const res = yield registerEnterprise(query);
            this.successBack('注册成功！')

            return typeof callback === 'function' && callback(res.data);
        } catch (error) {
            Toast.info(error.message || '注册失败', 2, null, false);
            return typeof callback === 'function' && callback(error);
        }
    })

    // 忘记密码
    @action resetPassword = flow(function* (query, callback) {
        try {
            const res = yield resetPassword(query);
            this.successBack('重置密码成功！')

            return typeof callback === 'function' && callback(res.data);
        } catch (error) {
            Toast.info(error.message || '重置密码失败', 2, null, false);
            return typeof callback === 'function' && callback(error);
        }
    })
    // 退出
    @action logout = flow(function* (query, callback) {
        try {
            const params ={
                phoneOrEmail: toJS(this.user).userName,
            }
            const res = yield logout(params);
            this.logoutBack()
            return typeof callback === 'function' && callback(res.data);
        } catch (error) {
            Toast.info(error.message || '退出失败', 2, null, false);
            return typeof callback === 'function' && callback(error);
        }
    })

}

export default new Store();