import React, { Suspense, Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch } from 'react-router-dom';
import { Spin } from 'antd';
import URoute from '@components/URoute';
import IRoute from '@components/IRoute';
import cookie from 'js-cookie';
import userInfo from '@store/userInfo.store';
import { getAccountDetail } from "./api/open_api";
import utils from '@common/utils.js'
import { observer } from 'mobx-react';
import App from './App';
import '@font/iconfont.css';
import './asserts/icon/iconfont.css';
import './index.scss';

import * as serviceWorker from './serviceWorker';
import ErrorBoundary from './ErrorBoundary'
import { toJS } from 'mobx';

// 实名认证
const RealName = React.lazy(() => import(/* webpackChunkName: "realName" */ './pages/singlePage/realName'));

// 录制视频
const Video = React.lazy(() => import(/* webpackChunkName: "realName" */ './pages/singlePage/video'));

// 上传签名
const UploadSign = React.lazy(() => import(/* webpackChunkName: "uploadSign" */ './pages/singlePage/uploadSign'));

// 实名认证结果
const Result = React.lazy(() => import(/* webpackChunkName: "result" */ './pages/singlePage/result'));

// 三方实名认证结果
const CustomResult = React.lazy(() => import(/* webpackChunkName: "result" */ './custom/pages/result'));

// 用户未登录
const UserUnlog = React.lazy(() => import(/* webpackChunkName: "loagin" */ './pages/unlogin'));
const Wechat = React.lazy(() => import(/* webpackChunkName: "loagin" */ './pages/singlePage/wechat'));

window.onerror = function (errorMessage, scriptURI, lineNo, columnNo, error) {
    console.error('err', error)
    //window.location.href = '/'
}

//const Index = function (props) {
@observer
class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    async thirdLogin() {
        try {
            let obj = utils.querySearch(window.location.href)
            if (obj.templateId) {
                //三方的用户签署
                if (obj.sessionId) {
                    const res = await getAccountDetail({ id: obj.sessionId })
                    userInfo.setLoginStatus(true)
                    cookie.set('loginstatus', true)
                    userInfo.setThirdType(res.data.isThird)
                    cookie.set('thirdtype', res.data.isThird)
                }
            } else if (obj.signId) {
                //三方的用户的签署详情
                if (obj.sessionId) {
                    const res = await getAccountDetail({ id: obj.sessionId })
                    userInfo.setLoginStatus(true)
                    cookie.set('loginstatus', true)
                    userInfo.setThirdType(3)
                    cookie.set('thirdtype', 3)
                }
            }
            let status = cookie.get('loginstatus');
            if (status === 'true') {
                userInfo.setLoginStatus(true)
            } else {
                userInfo.setLoginStatus(false)
            }
        } catch (error) {
            //window.location.href = '/'
            console.log(error)
        }
    }
    componentDidMount() {
        this.thirdLogin()
    }
    render() {
        let loginstatus = toJS(userInfo.loginStatus)
        let sessionId = utils.querySearch(window.location.href).sessionId
        let thirdtype = Number.parseInt(cookie.get('thirdtype'))
        let fromThird = sessionId || thirdtype
        return !fromThird ? <BrowserRouter>
            <Suspense fallback={
                <div className="app-loading">
                    <Spin size="large" />
                </div>
            }>
                <ErrorBoundary>
                    <Switch>
                        <URoute path="/realname/getuser" title="实名认证" render={(props) => { return <RealName {...props} /> }} />
                        <URoute path="/video" title="人像录制" render={(props) => { return <Video {...props} /> }} />
                        <URoute path="/realname" title="实名认证" render={(props) => { return <RealName {...props} /> }} />
                        <URoute path="/uploadSign" title="上传签章" render={(props) => { return <UploadSign {...props} /> }} />
                        <IRoute path="/custom/result" title="认证结果" render={(props) => { return <CustomResult {...props} /> }} />
                        <URoute path="/result" title="认证结果" render={(props) => { return <Result {...props} /> }} />
                        <URoute path="/wechat" title="微信" render={(props) => { return <Wechat {...props} /> }} />
                        <URoute path="/" title="首页" render={(props) => {
                            // 用户登录
                            if (loginstatus) {
                                return <App />
                            }
                            // 用户未登录
                            return <UserUnlog />
                        }} />
                    </Switch>
                </ErrorBoundary>
            </Suspense>
        </BrowserRouter> :
            <BrowserRouter>
                <Suspense fallback={
                    <div className="app-idou-loading">
                        <Spin size="large" />
                    </div>
                }>
                    <ErrorBoundary>
                        <Switch>
                            <IRoute path="/custom/result" title="认证结果" render={(props) => { return <Result {...props} /> }} />
                            <IRoute path="/custom/video" title="人脸识别" render={(props) => { return <Video {...props} /> }} />
                            <IRoute path="/wechat" title="微信" render={(props) => { return <Wechat {...props} /> }} />
                            <IRoute path="/" title="爱豆科技" render={(props) => {
                                // 用户登录
                                if (loginstatus) {
                                    return <App />
                                }
                                // 用户未登录
                                //return <UserUnlog />
                            }} />
                        </Switch>
                    </ErrorBoundary>
                </Suspense>
            </BrowserRouter>
    }
}
//}

ReactDOM.render(<Index />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();